import { useContext } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import * as Sentry from "@sentry/nextjs";

export interface GetImagenOptimizadaProps {
	src: string;
	width?: number;
	height?: number;
	resize?: string;
}

export const useImage = () => {
	const { country_code } = useContext(ConfigStateContext);

	const getImagenOptimizada = ({ src, width, height, resize }: GetImagenOptimizadaProps) => {
		if (!src) return ""

		const resizeImage = (url: string) => {
			if (!width || !height) return url
			const dimensions = `${Math.round(width)}x${Math.round(height)}`
			const resizeMode = resize ? resize : "outside"

			if (url.includes("cdn")) {
				const pattern = /cdn([1-9][0-9]*)/
				url = url.replace(pattern, "cdn2")

				if (hasImgExtension(url)) {
					let segments = url.split("/")
					segments[segments.length - 1] = `th.${resizeMode}${dimensions}.`.concat(segments[segments.length - 1])
					url = segments.join("/")
				}
			} else if (url.includes("/site/") && !url.includes("OVFR_COL")) {
				const breakPointString = url.indexOf('/site/')
				const secondSplit = url.substring(breakPointString)
				url = `https://d3s5pkt10pk3ga.cloudfront.net/resizedImages/${dimensions}${secondSplit}`
			}

			return url
		};

		return resizeImage(src)

	};

	const notifyError = ({
		property_id,
		image_url,
		image_id
	}) => {
		Sentry.withScope(scope => {
			scope.setTag("image_error", image_url);
			scope.setContext("image_error_context", {
				property_id,
				image_url,
				image_id
			});
			Sentry.captureException(new Error("image-custom-error"));
		});
	}

	return { getImagenOptimizada, notifyError };
};

const hasImgExtension = (src: string): boolean => /\.(jpg|png|JPG|PNG|JPEG|jpeg)/g.test(src);
